<template>
  <div class="dimbox log">
    <div class="mHeader">
      <p>관리자로그</p>
    </div>
    <div class="mBody">
      <div class="tableBox">

        <table class="logTable" v-if="dataInfo.length > 0">
          <colgroup>
            <col style="width: 25%" />
            <col style="width: 15%" />
            <col style="width: 60%" />
          </colgroup>
          <tr>
            <th>날짜</th>
            <th>작성자</th>
            <th>작업내용</th>
          </tr>
          <tr v-for="(data, i) in dataInfo" :key="i">
            <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:MM") }}</td>
            <td>{{ data.author.name }}</td>
            <td>{{ data.workDetails }}</td>
          </tr>
        </table>
        <div v-else class="nodata">
          <p>기록이 없습니다.</p>
        </div>
      </div>

      <div class="button">
        <button @click="close" class="main">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["dataInfo"],
  data() {
    return {
      moment: moment,
      workDetails: "",
    };
  },
  activated() {
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
