<template>
  <div class="contents">
    <div class="title flexB">
      <h2>
        Youtube 대시보드
        <span class="description" v-if="startDate && endDate">
          {{ startDate | formatDate }} ~ {{ endDate | formatDate }}
        </span>
      </h2>
      <p>{{ name }} ({{ team }})</p>
    </div>

    <div class="box table one">
      <div class="top">
        <div class="tit flex" style="width: 45%; display: inline-block">
          <div style="display: flex">
            <h2 class="bold">매출 Top 10 채널</h2>
            <p>매출∙지난 7일</p>
          </div>
        </div>
        <div
          class="tit flex"
          style="width: 45%; display: inline-block; margin-left: 40px"
        >
          <div style="display: flex">
            <h2 class="bold">조회수 Top 10 채널</h2>
            <p>조회수∙지난 7일</p>
          </div>
        </div>
      </div>

      <div style="width: 45%; overflow: auto; display: inline-block">
        <table
          class="dataTable styled-table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
        >
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 120px" />
            <col style="width: 140px" />
            <col style="width: 100px" />
          </colgroup>
          <tr>
            <th></th>
            <th>채널</th>
            <th>점유율</th>
            <th>매출(원)</th>
          </tr>
          <tr v-for="(data, i) in top10RevenueChannels" :key="i">
            <td>
              <div class="img thumbnail">
                <img :src="data.thumbnail" alt="대표썸네일" />
              </div>
            </td>
            <td class="channel-name">
              <a :href="data.url" target="_blank" rel="noopener noreferrer">
                {{ data.title }}
              </a>
            </td>
            <td>
              <el-progress
                :percentage="data.share | formatPercentage"
                stroke-width="8"
              />
            </td>

            <td>{{ data.value | formatKRW }}</td>
          </tr>
        </table>
        <NoData v-if="isNodata" />
      </div>
      <div
        style="
          width: 45%;
          overflow: auto;
          display: inline-block;
          margin-left: 40px;
        "
      >
        <table
          class="dataTable styled-table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
        >
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 120px" />
            <col style="width: 140px" />
            <col style="width: 100px" />
          </colgroup>
          <tr>
            <th></th>
            <th>채널</th>
            <th>점유율</th>
            <th>조회수</th>
          </tr>
          <tr v-for="(data, i) in top10MostViewedChannels" :key="i">
            <td>
              <div class="img thumbnail">
                <img :src="data.thumbnail" alt="대표썸네일" />
              </div>
            </td>
            <td class="channel-name">
              <a :href="data.url" target="_blank" rel="noopener noreferrer">
                {{ data.title }}
              </a>
            </td>
            <td>
              <el-progress
                :percentage="data.share | formatPercentage"
                stroke-width="8"
              />
            </td>

            <td>{{ data.value | formatKR10Thousand }} 만</td>
          </tr>
        </table>
        <NoData v-if="isNodata" />
      </div>
      <div
        style="width: 100%; overflow: auto; display: inline-block; height: 50px"
      />
      <div class="top">
        <div class="tit flex" style="width: 45%; display: inline-block">
          <div style="display: flex">
            <h2 class="bold">시청시간 Top 10 채널</h2>
            <p>시청시간∙지난 7일</p>
          </div>
        </div>
        <div
          class="tit flex"
          style="width: 45%; display: inline-block; margin-left: 40px"
        >
          <div style="display: flex">
            <h2 class="bold">시청시간 Top 10 지역</h2>
            <p>시청시간∙지난 7일</p>
          </div>
        </div>
      </div>
      <div style="width: 45%; overflow: auto; display: inline-block">
        <table
          class="dataTable styled-table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
        >
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 120px" />
            <col style="width: 140px" />
            <col style="width: 100px" />
          </colgroup>
          <tr>
            <th></th>
            <th>채널</th>
            <th>점유율</th>
            <th>시청 시간 (시간)</th>
          </tr>
          <tr v-for="(data, i) in top10ChannelsForWatchTime" :key="i">
            <td>
              <div class="img thumbnail">
                <img :src="data.thumbnail" alt="대표썸네일" />
              </div>
            </td>
            <td>
              <a :href="data.url" target="_blank" rel="noopener noreferrer">
                {{ data.title }}
              </a>
            </td>
            <td>
              <el-progress
                :percentage="data.share | formatPercentage"
                stroke-width="8"
              />
            </td>

            <td>{{ (data.value / 60) | formatKRW }}</td>
          </tr>
        </table>
        <NoData v-if="isNodata" />
      </div>
      <div
        style="
          width: 45%;
          overflow: auto;
          display: inline-block;
          margin-left: 40px;
          vertical-align: top;
        "
      >
        <table
          class="dataTable styled-table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
        >
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 120px" />
            <col style="width: 140px" />
            <col style="width: 100px" />
          </colgroup>
          <tr>
            <th>지역</th>
            <th>평균 시청<br />지속 시간 (분:초)</th>
            <th>점유율</th>
            <th>시청 시간 (시간)</th>
          </tr>
          <tr v-for="(data, i) in top10RegionsForWatchTime" :key="i">
            <td>
              {{ data.countryName }}
            </td>
            <td>
              {{ data.averageViewDuration | formatSeconds2MinutesAndSeconds }}
            </td>
            <td>
              <el-progress
                :percentage="data.share | formatPercentage"
                stroke-width="8"
              />
            </td>
            <td>{{ (data.estimatedMinutesWatched / 60) | formatKRW }}</td>
          </tr>
        </table>
        <NoData v-if="isNodata" />
      </div>
    </div>
    <div class="pagination" v-if="metadataList.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>

      <transition name="fade">
        <div class="dim" v-if="visible">
          <AdminLog @close="close" :dataInfo="dataInfo" />
          <div class="dimbg" @click="close"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment, { duration } from "moment";
import { format } from "@/mixins/format";
import AdminLog from "@/components/pop/AdminLog.vue";
import { fetchYoutubeDashboardData } from "@/api";
import { mapState } from "vuex";
import Vue from "vue";

Vue.filter("formatKRW", function (value) {
  return new Intl.NumberFormat("ko-KR", {
    maximumFractionDigits: 0,
  }).format(value);
});
Vue.filter("formatKR10Thousand", function (value) {
  return new Intl.NumberFormat("ko-KR", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value / (10 * 1000));
});
Vue.filter("formatSeconds2MinutesAndSeconds", function (value) {
  return moment.utc(duration(value, "seconds").asMilliseconds()).format("m:ss");
});
Vue.filter("formatDate", function (value) {
  return moment(value).format("YYYY. M. DD.");
});

Vue.filter("formatPercentage", function (value) {
  return new Intl.NumberFormat("ko-KR", {
    maximumFractionDigits: 1,
  }).format(value * 100);
});

export default {
  mixins: [format],
  name: "DataManage",
  components: { AdminLog },
  data() {
    return {
      moment: moment,
      ipType: "",
      contentType: "",
      inputMode: "",
      searchWord: "",
      metadataList: [],
      dumpList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
      ip: "",
      contents: "",
      making: "",
      visible: false,
      dataInfo: {},
      dataLogList: [],
      uploadDisabled: false,
      serverPath: "",
      startDate: "",
      endDate: "",
      top10RevenueChannels: [],
      top10MostViewedChannels: [],
      top10ChannelsForWatchTime: [],
      top10RegionsForWatchTime: [],
    };
  },
  computed: {
    ...mapState(["name", "team", "_id"]),
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "-1");
    this.getYoutubeDashboardData();
  },
  methods: {
    customFormatter(index) {
      return moment(index).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.ipType = "";
      this.contentType = "";
      this.inputMode = "";
      this.searchWord = "";
      this.dataList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getMetadataList();
    },
    handleMove(id) {
      this.$router.push({ name: "dataRegister", query: { id: id } });
    },
    close() {
      this.visible = false;
    },

    async getYoutubeDashboardData() {
      const response = await fetchYoutubeDashboardData();
      if (response.data.status == 200) {
        const data = response.data;

        this.startDate = data.data.startDate;
        this.endDate = data.data.endDate;
        this.top10RevenueChannels = data.data.revenueRank;
        this.top10MostViewedChannels = data.data.viewsRank;
        this.top10ChannelsForWatchTime = data.data.watchTimeRank;
        this.top10RegionsForWatchTime = data.data.countryRanks;
      } else {
        alert(response.data.message);
      }
    },
  },
};
</script>
